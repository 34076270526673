<template>
    <span>
        <template v-if="estimate">
            <v-tooltip
                :disabled="!estimate.isComment || this.$auth.member.uuid !== estimate.student_uuid"
                bottom
            >
              <template v-slot:activator="{ on, attrs }">
                  <span
                      v-bind="attrs"
                      v-on="on"
                  >
                      <span :class="`${estimate.color}--text`">{{ estimate.text }}</span>
                        <v-icon
                            v-if="estimate.isComment"
                            x-small
                            color="secondary"
                            class="mt-n2"
                        >
                            mdi-comment-text-outline
                        </v-icon>
                  </span>
              </template>
                <span>{{ estimate.comment }}</span>
            </v-tooltip>
        </template>
        <v-icon v-else>mdi-dots-horizontal</v-icon>
    </span>
</template>

<script>

export default {
    name: 'EstimateLabel',
    props: {
        estimate: {
            type: Object,
            default: null
        }
    }
}
</script>

<style lang=scss>

</style>

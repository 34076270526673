<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
        comeback-previous
        class="day-book-form"
    >
        <div>
            <v-card
                :disabled="$lprogress.isLoading()"
                outlined
                class="overflow-hidden"
            >
                <div class="pa-4 text-sm-center">
                    <h4 class="text-truncate">{{ $trans('Day book') }}: {{ groupInst.title }}</h4>
                    <div v-if="courseInst.title" class="caption text-truncate">
                        {{ courseInst.title }}, {{ groupInst.level.title }} ({{ groupInst.level.school.title }})
                    </div>
                    <div style="max-width: 300px" class="mx-sm-auto py-2">
                        <v-select
                            v-model="studyPeriodSelected"
                            dense
                            hide-details
                            :items="studyPeriods"
                            @change="fetchData"
                        />
                    </div>
                </div>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                {{ $trans('Student') }}
                            </th>
                            <th
                                v-for="lesson in lessons"
                                :key="`${lesson.uuid}-th`"
                                class="lesson-th text-center"
                            >
                                <div>
                                    <small
                                        v-if="lesson.lesson_type.group === 'final'"
                                        class="success--text"
                                    >
                                        {{ $trans('National scale') }}
                                    </small> <br>
                                    {{ lesson.begin | dateFormat('DD-MM-YY HH:mm') }} <br>
                                    <small>{{ lesson.lesson_type.title }}</small>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(student, i) in students"
                            :key="`${student.uuid}-${i}`"
                        >
                            <td :key="`${student.uuid}-name`">
                                <member-full-name
                                    :item="student"
                                    :mode="isMobile ? 'short' : undefined"
                                    :hide-avatar="isMobile"
                                />
                            </td>
                            <td
                                v-for="(lesson, j) in lessons"
                                :key="`${lesson.uuid}-${i}-${j}-td`"
                                class="lesson-td text-center"
                            >
                                <div v-if="lesson.teacher_uuid === memberUuid">
                                    <v-btn
                                        block
                                        text
                                        tile
                                        :color="estimatesValues[student.uuid][lesson.uuid] ? estimatesValues[student.uuid][lesson.uuid].color : 'primary'"
                                        min-height="3.125rem"
                                        @click="onShowForm(student, lesson)"
                                    >
                                        <estimate-label
                                            :estimate="estimatesValues[student.uuid][lesson.uuid]"
                                        />
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <estimate-label
                                        :estimate="estimatesValues[student.uuid][lesson.uuid]"
                                    />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
            <w-dialog-drawer
                v-model="dialog"
                :title="currentStudent | fullName"
            >
                <div class="pa-4">
                    <v-card
                        outlined
                        class="pa-4 mb-4"
                    >
                        <w-options-list
                            :items="formOptions"
                        />
                    </v-card>
                    <w-simple-form
                        :key="formKey"
                        v-bind="formProps"
                        @save="onSaveForm"
                        @update="onUpdateForm"
                        @delete="onDeleteForm"
                    />
                </div>
            </w-dialog-drawer>
        </div>
    </content-wrapper>
</template>

<script>
import { getDayBookGroup } from '@apps/school/api/daybook'
import MemberFullName from '@/components/Member/MemberFullName'
import ContentWrapper from '@/components/ContentWrapper'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import { required } from '@/utils/validation'
import moment from 'moment'
import { deleteEstimate, getEstimates, storeEstimate, updateEstimate } from '@apps/school/api/estimates'
import ConfirmationSets from '@/utils/ConfirmationSets'
import EstimateLabel from '@apps/school/components/Estimate/EstimateLabel'
import hasScoresPropertyMixin from '@apps/school/mixins/hasScoresPropertyMixin'
import hasEstimateColorResolverMixin from '@apps/school/mixins/hasEstimateColorResolverMixin'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'DayBookForm',
    components: {
        EstimateLabel,
        ContentWrapper,
        MemberFullName
    },
    mixins: [
        mobileBreakpointChecker,
        hasMemberFullNameFilterMixin,
        hasScoresPropertyMixin,
        hasEstimateColorResolverMixin,
    ],
    data: () => ({
        formKey: 0,
        courseInst: {},
        departmentInst: {},
        groupInst: {
            title: '',
            level: {
                school: {}
            }
        },
        studyPeriodSelected: null,
        lessons: [],
        students: [],
        estimates: [],
        currentStudent: {},
        currentLesson: {
            lesson_type: {}
        },
        currentEstimate: {},
        dialog: false
    }),
    computed: {
        memberUuid() {
            return this.$auth.member ? this.$auth.member.uuid : null
        },
        course() {
            return this.$route.query.c
        },
        group() {
            return this.$route.query.g
        },
        studyPeriods() {
            const collection = this.$store.getters['school/studyPeriods']

            return collection.map(o => {
                const values = o.values
                return {
                    value: o.uuid,
                    text: `${values.label} (${values.begin} - ${values.end})`
                }
            })
        },
        currentStudyPeriod() {
            return this.$store.getters['school/currentStudyPeriod']
        },
        comback() {
            if(!this.prevRoute) return ''

            return this.prevRoute.fullPath
        },
        breadcrumbsForTeacher() {
            const breadcrumbs = [
                {
                    text: this.$trans('Day book'),
                    to: { name: 'daybook.index' }
                }
            ]

            if(this.groupInst.uuid) {
                breadcrumbs.push({
                    text: `${this.$trans('Group')} ${this.groupInst.title}`,
                    to: {name: 'groups.daybooks', params: {uuid: this.groupInst.uuid}}
                })
            }

            if(this.courseInst.uuid) {
                breadcrumbs.push({
                    text: this.courseInst.title,
                    to: {name: 'courses.groups', params: {uuid: this.courseInst.uuid}}
                })
            }

            return breadcrumbs
        },
        breadcrumbsForStudent() {
            const breadcrumbs = [
                {
                    text: this.$trans('Day book'),
                    to: { name: 'daybook.index' }
                }
            ]

            if(this.courseInst.uuid) {
                breadcrumbs.push({
                    text: this.courseInst.title,
                    to: {name: 'courses.docs', params: {uuid: this.courseInst.uuid}}
                })
            }

            return breadcrumbs
        },
        breadcrumbs() {
            return this.$auth.isTeacher()
                ? this.breadcrumbsForTeacher
                : this.breadcrumbsForStudent
        },
        headers() {
            const headers = []

            headers.push({
                text: this.$trans('Student'),
                value: 'student',
                sortable: false
            })

            return headers
        },
        estimatesValues() {
            const estimates = {}

            this.students.forEach(o => {
                estimates[o.uuid] = {}
            })

            this.estimates.forEach(o => {
                const value = o.status ? o.status : o.score

                estimates[o.student_uuid][o.lesson_uuid] = {
                    value,
                    student_uuid: o.student_uuid,
                    text: o.status ? this.$trans(`estimates.${o.status}`) : o.score,
                    isComment: !!o.comment,
                    comment: o.comment,
                    color: this.estimateColorResolver(value)
                }
            })

            return estimates
        },
        formProps() {
            const form = {
                item: this.currentEstimate,
                deletable: true
            }
            const items = []
            let name = null

            if (this.currentLesson.lesson_type.group !== 'final') {
                name = 'score'

                for (const x of Array(this.scores.max_score).keys()) {
                    if (x >= this.scores.min_score) {
                        items.push({
                            text: x + 1,
                            value: (x + 1).toString()
                        })
                    }
                }
            } else {
                name = 'status'

                this.scores.statuses.forEach(o => {
                    items.push({
                        text: this.$trans(`estimates.${o}`).toUpperCase(),
                        value: o
                    })
                })
            }

            form.fields = [
                {
                    type: 'v-autocomplete',
                    name: name,
                    props: {
                        label: this.$trans('Estimate'),
                        dense: true,
                        outlined: true,
                        items: items,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    type: 'v-textarea',
                    name: 'comment',
                    props: {
                        label: this.$trans('Comment'),
                        dense: true,
                        outlined: true,
                        rows: 2
                    }
                }
            ]

            return form
        },
        formOptions() {
            moment.updateLocale(this.$store.getters.locale, {})

            const begin = this.currentLesson.begin
            const end = this.currentLesson.end

            return [
                {
                    title: this.courseInst.title,
                    subtitle: this.currentLesson.lesson_type.title,
                    icon: 'COURSE'
                },
                {
                    title: moment(begin).format('dddd, DD MMMM, YYYY'),
                    icon: 'CALENDAR'
                },
                {
                    title: moment(begin).format('HH:mm') + ' - ' + moment(end).format('HH:mm'),
                    icon: 'CLOCK'
                }
            ]
        }
    },
    watch: {
        dialog(flag) {
            if (flag) {
                this.formKey++
            }
        },
        currentStudyPeriod(value) {
            this.studyPeriodSelected = value
                ? value.uuid
                : null

            this.fetchData(this.studyPeriodSelected)
        }
    },
    async mounted() {
        this.studyPeriodSelected = this.currentStudyPeriod
            ? this.currentStudyPeriod.uuid
            : null

        await this.fetchData(this.studyPeriodSelected)
    },
    methods: {
        async fetchData(period) {
            const options = {
                course: this.course,
                group: this.group,
                period: period
            }

            if(!period) {
                return
            }

            if (!options.teacher && !options.course && !options.group) {
                return
            }

            this.$lprogress.begin()

            try {
                const response = await getDayBookGroup(options)
                const data = response.data

                this.students = data.data
                this.lessons = data.lessons
                this.courseInst = data.course
                this.departmentInst = data.department
                this.groupInst = data.group
                this.estimates = data.estimates
            } finally {
                this.$lprogress.end()
            }
        },
        fetchEstimates() {
            const payload = {
                group: this.group,
                course: this.course
            }

            getEstimates(payload)
                .then(response => {
                    this.estimates = [].concat(response.data.data)
                })
        },
        onShowForm(student, lesson) {
            const findEstimate = (student, lesson) => {
                return this.estimates.find(o => {
                    return o.student_uuid === student.uuid &&
                        o.lesson_uuid === lesson.uuid
                })
            }

            this.currentStudent = Object.assign({}, student)
            this.currentLesson = Object.assign({}, lesson)
            this.currentEstimate = Object.assign({}, findEstimate(student, lesson))
            this.dialog = true
        },
        onSaveForm(data) {
            this.$lprogress.begin()

            data.lesson_uuid = this.currentLesson.uuid
            data.student_uuid = this.currentStudent.uuid

            storeEstimate(data)
                .then(response => {
                    this.$lprogress.end()
                    this.fetchEstimates()
                    this.dialog = false
                })
                .catch(() => {
                    this.$lprogress.end()
                    this.dialog = false
                })
        },
        onUpdateForm(data, item) {
            this.$lprogress.begin()

            updateEstimate(item.uuid, data)
                .then(response => {
                    this.$lprogress.end()
                    this.fetchEstimates()
                    this.dialog = false
                })
                .catch(() => {
                    this.$lprogress.end()
                    this.dialog = false
                })
        },
        async onDeleteForm(data, item) {
            this.$lprogress.begin()

            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet())

            if (!isConfirm) {
                return
            }

            try {
                await deleteEstimate(item.uuid)
                await this.fetchEstimates()
                this.dialog = false
                this.$lprogress.end()
            } catch (error) {
                this.$lprogress.end()
                this.dialog = false
            }
        }
    }
}
</script>

<style lang=scss>
.day-book-form {
    .lesson-th {
        div {
            writing-mode: vertical-rl;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            display: inline-block;
            height: 150px !important;
        }
    }

    .lesson-td {
        padding: 0 !important;
    }

    .lesson-th, .lesson-td {
        border-left: thin solid rgba(94, 86, 105, 0.14);
    }

    .estimate-comment-icon {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}
</style>

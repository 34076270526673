<template>
    <v-card
        flat
        color="transparent"
        :to="to"
        max-width="100%"
        class="d-flex align-center"
    >
        <member-avatar
            v-if="!hideAvatar"
            :item="item"
        />
        <div class="d-flex flex-column ms-3">
            <div
                class="text--primary font-weight-semibold text-truncate text-decoration-none"
                style="flex: 1"
            >
                {{ item | memberFullName(mode) }}
            </div>
            <small v-if="subtitle">{{ subtitle }}</small>
        </div>
    </v-card>
</template>

<script>
import MemberAvatar from '@/components/Member/MemberAvatar'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'

export default {
    name: 'MemberFullName',
    mixins: [
        hasMemberFullNameFilterMixin
    ],
    components: { MemberAvatar },
    props: {
        item: {
            type: Object,
            required: true
        },
        subtitle: {
            type: String,
            default: undefined
        },
        to: {
            type: Object,
            default: undefined
        },
        mode: {
            type: String,
            default: undefined
        },
        hideAvatar: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">

</style>

/* eslint-disable */
import request from '@/plugins/request'

const prefix = 'daybook'

export const getDayBookCourses = payload => {
	const options = {
		method: 'GET',
		url: `${prefix}/courses`,
		params: payload
	}

	return request(options)
}

export const getDayBookGroup = payload => {
    const options = {
        method: 'GET',
        url: `${prefix}/group`,
        params: payload
    }

    return request(options)
}

import hasScoresPropertyMixin from '@apps/school/mixins/hasScoresPropertyMixin'

export default {
    mixins: [ hasScoresPropertyMixin ],
    methods: {
        estimateColorResolver(value) {
            const scores = this.scores

            const statusesColors = {
                passed: 'success',
                fail: 'error',
                perfectly: 'success',
                fine: 'info',
                satisfactorily: 'warning',
                unsatisfactorily: 'error'
            }

            if (Object.keys(statusesColors).includes(value)) {
                return statusesColors[value]
            }

            const scoreStep = (scores.max_score - scores.min_score) / 4
            const valueNum = Number(value)

            if (valueNum <= scoreStep) {
                return 'error'
            } else if (valueNum <= (scoreStep * 2)) {
                return 'warning'
            } else if (valueNum <= (scoreStep * 3)) {
                return 'info'
            } else {
                return 'success'
            }
        }
    }
}

/* eslint-disable */
import request from '@/plugins/request'

const prefix = 'estimates'

/**
 *
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const getEstimates = payload => {
    const options = {
        method: 'GET',
        url: `${prefix}`,
        params: payload
    }

    return request(options)
}

/**
 *
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const storeEstimate = data => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateEstimate = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @returns {AxiosPromise}
 */
export const deleteEstimate = uuid => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}

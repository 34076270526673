<template>
    <v-avatar
        :color="avatar ? '' : 'primary'"
        :class="avatarClasses"
        :size="size"
        :rounded="rounded"
        v-on="on"
    >
        <v-img
            v-if="avatar"
            :src="avatar"
        ></v-img>
        <span
            v-else
            class="font-weight-medium"
        >
                {{ avatarText }}
            </span>
    </v-avatar>
</template>

<script>
import _ from 'lodash'
import { avatarText } from '@/utils/filter'
import { getFile } from '@apps/media/api'

export default {
    name: 'MemberAvatar',
    props: {
        item: {
            type: Object,
            required: true
        },
        size: {
            type: [ Number, String ],
            default: 32
        },
        rounded: {
            type: Boolean,
            default: false
        },
        on: {
            type: Object,
            default: null
        }
    },
    computed: {
        avatarClasses() {
            const classes = []

            if(!this.avatar) {
                classes.push('v-avatar-light-bg primary--text')
            }

            if(this.on) {
                classes.push('cursor-pointer')
            }

            return classes
        },
        lastName() {
            return _.get(this.item, 'last_name', null)
        },
        firstName() {
            return _.get(this.item, 'first_name', null)
        },
        avatar() {
            const src = _.get(this.item, 'avatar', null)
            return src ? getFile(src) : null
        },
        avatarText() {
            return !_.isEmpty(this.item)
                ? avatarText(`${this.lastName} ${this.firstName}`)
                : ''
        }
    }
}
</script>

<style lang=scss>

</style>
